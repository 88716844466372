import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Posts from 'components/Posts';

const BlogPage: React.FC = () => {
  const metaDisc = "All blogs by Datt Dongare. I write about Programming, business, startup, technology and lifestyle improvement. Check the list of blogs I've written so far."
  return (
    <Layout>
      <SEO title="Programming and Business Blogs" description={metaDisc} />
      <Posts />
    </Layout>
  );
};

export default BlogPage;
